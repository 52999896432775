import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Copyright from "../components/Copyright";
import Logo from "../components/Logo";
import useStyles from "../helpers/styles";
import Wrapper from "../helpers/FormWrapper";
import Link from "@material-ui/core/Link";
import { Link as GLink } from "gatsby";
import "../helpers/getDestUrl";

const Page404 = () => {
  const classes = useStyles();

  const backUrl = () => {
    if (typeof window !== "undefined") {
      window.location = window.sessionStorage.getItem("dest");
    }
  };

  return (
    <Box className={classes.paper} align="center">
      <Logo />
      <Typography>Looks like we took a wrong turn somewhere.</Typography>
      <br />
      <Typography>
        We are here to get you back on track to finish your journey.
      </Typography>

      <Box>
        <br />
        <Typography>
          <Link onClick={backUrl}>Back to MapQuest</Link>
        </Typography>

        <Typography>
          <GLink to="/" className={classes.primary} variant="body2">
            SignIn
          </GLink>
        </Typography>
      </Box>

      <Box className={classes.box} mt={5}>
        <Copyright />
      </Box>
    </Box>
  );
};

export default function () {
  return (
    <Wrapper>
      <Page404 />
    </Wrapper>
  );
}
